<template>
  <el-table-column
      v-if="column.visible"
      :prop="column.prop"
      :type="column.type"
      :width="column.width"
      resizable>
    <template v-slot:header>
      <core-text
          :key="column.label"
          :data-test-id="`column-header-${columnKey}`"
          truncate>
        {{ column.label }}
      </core-text>
    </template>

    <template slot-scope="scope">
      <component
          :is="column.Component"
          v-if="column.Component"
          :data-test-id="`row-${scope.row.id}-column-${columnKey}`"
          :scope="scope"
          v-on="$listeners" />
      <core-text
          v-else
          :key="getCellValue(scope.row)"
          :data-test-id="`row-${scope.row.id}-column-${columnKey}`"
          truncate>
        <span :data-test-id="`row-${scope.row.id}-span`">
          {{ getCellValue(scope.row) }}
        </span>
      </core-text>
    </template>
  </el-table-column>
</template>

<script>
import get from "lodash/get"

export default {
  name: "CoreDataTableColumn",

  props: {
    column: { type: Object, default: () => ({}) },
    columns: { type: Array, default: () => [] },
    columnKey: { type: Number, default: null }
  },
  methods: {
    getCellValue(row) {
      return `${get(row, this.column.prop, "-")}`
    }
  }
}
</script>

<style lang="scss" scoped>
.column-header-container {
  padding: 1em 0 0 0;
  display: flex;
  align-items: center;
  i {
    margin-left: 0.5rem;
  }
}
::v-deep  .info-tooltip {
  max-width: 100px;
}
</style>
